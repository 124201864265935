import axios from 'axios'
import i18n from "@/plugins/i18n";
import router from "@/router";
import store from '@/store';
import Cookie from "js-cookie";
const locale = i18n.global.locale.value;

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

axiosInstance.interceptors.request.use(request => {
    const token = store.getters['account/token'] || Cookie.get('token') || null;
    const locale = store.getters['locale'];
    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`
      request.headers['Accept-Language'] = locale
    }

    return request
  },
  function (error) {
    return Promise.reject(error)
  }
)


axiosInstance.interceptors.response.use(null, error => {
  if (error.response.status === 401) {
    const currentLocale = locale === 'ru' ? '' : `/${locale}`
    store.commit('account/setToken', null, {root: true})
    Cookie.remove('token')
    router.push(`${currentLocale}/login`)
  }
  return Promise.reject(error)
})

export default axiosInstance
