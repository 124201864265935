import axios from "@/plugins/axios";
import Cookie from "js-cookie";

export default {
  namespaced: true,
  state() {
    return {
      token: Cookie.get('token'),
      abonent: null,
      auth: null,
      captcha: null
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      Cookie.set('token', token, { expires: 366, secure: true, sameSite: 'Strict' });
    },
    setAbonent(state, data) {
      state.abonent = data
    },
    setAuth(state, data) {
      state.auth = data
    },
    setCaptcha(state, data) {
      state.captcha = data
    },
    logout(state) {
      state.token = null
      Cookie.remove('token')
      // localStorage.removeItem(TOKEN_KEY)
    }
  },
  actions: {
    async login({ commit, dispatch, rootGetters }, payload) {
        try {
        // const payload = {login: 'tps2455106', password: 'tps24551060'}
        // const payload = {login: 'tpsfttb', password: 'tpsfttb'}
        // const payload = {login: 'tpsfttb2', password: 'tpsfttb2'}
        // const payload = {login: 'tpsadsl', password: 'tpsadsl'}
        const locale = rootGetters['locale'];
        const {data} = await axios.post('/api/login', {...payload}, {
          headers: {
            'Accept-Language': locale
          }
        })
        if( !data?.status ) {
          commit('setToken', data?.token || null)
          commit('setAbonent', data?.info?.abonent || null)
          commit('setAuth', data?.authResponse || null)
          commit('contract/setContract', data?.info?.contracts_info?.contract || null, { root: true })
          commit('service/setService', data?.info?.contracts_info?.contract?.services?.service || null, { root: true })
          commit('jetPoints/setTariffsTurbo', data?.jetBalance?.accounts?.account || null, { root: true })
        } else {
          throw new Error(data?.data?.o_Out_Text || data?.message); 
        }
      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async logout({ commit, dispatch }) {
      try {
        const {data} = await axios.post('/api/logout')
        commit('logout')
      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async fetchCaptcha({ commit, dispatch }) {
      try {
        const {data} = await axios.get('/captcha/api/default')
        commit('setCaptcha', data);

      } catch (e) {
        dispatch('setMessage', {
          value: e?.response?.data?.message || e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async changePassword({ commit, dispatch }, { subject_id, form_data }) {
      try {
        const {data} = await axios.post(`/api/change-password/${subject_id}`, form_data)
        if( data?.original?.response?.o_Result === "1" ) {
          dispatch('logout');
          dispatch('setMessage', {
            value: data?.original?.message,
            type: 'success'
          }, {root: true})
          return { status: data?.original?.response?.o_Result }
        } else {
          throw new Error(data?.original?.message || data?.message); 
        }
      } catch (e) {
        dispatch('fetchCaptcha')
        dispatch('setMessage', {
          value: e?.response?.data?.message || e.message,
          type: 'error'
        }, {root: true})
      }
    },
  },
  getters: {
    token(state) {
      return state.token
    },
    abonent(state) {
      return state.abonent
    },
    auth(state) {
      return state.auth
    },
    captcha(state) {
      return state.captcha
    },
    isAuthenticated(_, getters) {
      return !!getters.token
    }
  }
}